<template>
  <div>
    <div class="logo-wrapper">
      <img class="logo" src="../assets/graphics/logo.png" alt="logo">
    </div>
    <div class="banner-wrapper">
      <img class="banner-image" :src="getHeroImg()" alt="" :class="imagePositioning">
    </div>
    <nav class="body-wrapper">
      <button id="nav-toggle" class="nav-toggle" aria-label="Hauptnavigation togglen"
              :aria-expanded="expandedNavigation" @click="expandedNavigation=!expandedNavigation">
        <div id="hamburger-menu"></div>
      </button>
      <ul class="navigation" :class="{'toggled-navigation': expandedNavigation}">
        <li>
          <router-link :to="{path: '/', hash:'#start'}"
                       v-slot="{ isActive, isExactActive }"
                       @click="expandedNavigation = false"
          >
            <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              START</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{path: '/aboutme', hash:'#aboutme'}" v-slot="{ isActive, isExactActive }"
                       @click="expandedNavigation = false"
          >
            <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              ÜBER MICH</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{path: '/trauung', hash:'#trauung'}" v-slot="{ isActive, isExactActive }"
                       @click="expandedNavigation = false"
          >
            <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              FREIE TRAUUNG</span>

          </router-link>
        </li>
        <li>
          <router-link :to="{path: '/ablauf', hash:'#ablauf'}" v-slot="{ isActive, isExactActive }"
                       @click="expandedNavigation = false"
          >
            <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              ABLAUF</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{path: '/contact', hash:'#contact'}" v-slot="{ isActive, isExactActive }"
                       @click="expandedNavigation = false"
          >
            <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              KONTAKT</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
const PAGE_IMAGE_RELATION = {
  'start': 'start_hero.jpg',
  'aboutme': 'aboutme_hero.jpg',
  'trauung': 'trauung_hero.jpg',
  'ablauf': 'ablauf_hero.jpg',
  'contact': 'contact_hero.jpg'
}

export default {
  name: 'WscHeader',
  data: () => {
    return {
      isNavOpen: false,
      expandedNavigation: false,
    }
  },
  computed: {
    imageSrc() {
      return PAGE_IMAGE_RELATION[this.$route.path.slice(1) || 'start']
    },
    imagePositioning() {
      return this.$route.path.slice(1) || 'start'
    }
  },
  methods: {
    openNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    getHeroImg() {
      return require('../assets/images/' + this.imageSrc)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.start {
  object-position: 50%;
}

.aboutme {
  object-position: top;
}

.contact {
  object-position: 50% 58%;
}

.logo-wrapper {
  width: 100%;
  margin: 1em 0;
  background-color: white;
}

.logo {
  margin: 0 auto;
  width: 25%;
  height: 10%;
}

.banner-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ------------------ */


.body-wrapper {
  position: relative;
  min-height: 40px;
  height: 100%;
}

.navigation {
  grid-column: span 12;
  display: none;
  align-items: center;
  font-size: clamp(0.5rem, 2vw + 0.5rem, 1.5rem);
  margin: 1em 0;
  padding: 0;
  position: relative;
}

.toggled-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigation li a {
  text-decoration: none;
  font-family: BrandonTextMedium, Fallback, sans-serif;
  /*font-weight: 500;*/
  color: var(--primary-400);
}

.navigation a:hover, .navigation a:focus {
  cursor: pointer;
  color: var(--primary-600);
}

.navigation li {
  list-style: none;
  font-family: BrandonTextMedium, Fallback, sans-serif;
  /*font-weight: 500;*/
  color: var(--primary-400);
}

/* for current visited site */
.router-link-active {
  color: var(--primary-600);
}

/* hamburger menu button */
.nav-toggle {
  background-color: white;
  border: none;
  padding: 1.5em 1.25em;
  width: fit-content;
  display: flex;
  color: var(--primary-400);
}

.nav-toggle:hover, .nav-toggle:focus {
  cursor: pointer;
  color: var(--primary-600);
}

/* hamburger menu icon start */
.nav-toggle div,
.nav-toggle div::before,
.nav-toggle div::after {
  display: block;
  background: var(--primary-400);
  height: 2px;
  width: 1.5em;
  border-radius: 2px;
  position: relative;
}

/* hamburger menu icon active state */
.nav-toggle[aria-expanded=true] div,
.nav-toggle[aria-expanded=true] div::before,
.nav-toggle[aria-expanded=true] div::after,
.nav-toggle:hover div,
.nav-toggle:hover div::before,
.nav-toggle:hover div::after,
.nav-toggle:focus div,
.nav-toggle:focus div::before,
.nav-toggle:focus div::after {
  background: var(--primary-600);
}

.nav-toggle div::before,
.nav-toggle div::after {
  content: '';
  position: absolute;
}

.nav-toggle div::before {
  bottom: 7px;
}

.nav-toggle div::after {
  top: 7px;
}

/* hamburger menu end */


@media (min-width: 575px) {
  .banner-wrapper {
    height: 325px;
  }
}

@media (min-width: 768px) {
  .navigation {
    grid-column: 2/12;
    justify-content: space-between;
  }
}


@media (min-width: 900px) {
  .banner-wrapper {
    height: 375px;
  }
}


@media (min-width: 900px) and (min-height: 660px) {
  .banner-wrapper {
    height: 400px;
  }
}


@media (min-width: 900px) {
  .logo-wrapper {
    margin: 2em 0;
  }

  .navigation {
    display: flex;
  }

  .nav-toggle {
    display: none;
  }
}

@media (min-width: 1000px) {
  .banner-wrapper {
    height: 450px;
  }
}

@media (min-width: 1300px) {
  .navigation {
    grid-column: 3/11;
  }
}

@media (min-width: 1700px) and (min-height: 1000px) {
  .logo-wrapper {
    margin: 3em 0;
  }

  .banner-wrapper {
    height: 700px;
  }
}

</style>
