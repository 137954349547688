<template>
  <div id="aboutme" class="body-wrapper">
    <div class="main-content">
      <h1>
        ÜBER MICH
      </h1>
      <div class="context">
        <p>
          Mein Name ist Corinna, ich wurde 1988 in Unterfranken geboren.<br>
          Vor einigen Jahren verlegte ich der Liebe wegen meinen Lebensmittelpunkt nach Oberbayern,
          genauer gesagt nach Oberhaching, südlich von München. Hauptberuflich bin ich Standesbeamtin in Grasbrunn,
          nebenberuflich erfülle ich mir den Wunsch als Freie Traurednerin.
        </p>
        <p>
          Als Standesbeamtin und Traurednerin verbinde ich das Formale mit dem Schönen – aus Leidenschaft.
          Es ist mir ein Herzenswunsch Liebesgeschichten individuell in Worte zu fassen.
          Eine Freie Trauung lässt Eure Geschichte als unvergessliches Ereignis gemeinsam mit Euren Gästen aufleben.
          Es ist mir immer ein großes Anliegen, mit Worten etwas auszudrücken, was Menschen tief im Herzen berührt und
          ein Leben lang in liebevoller Erinnerung bleibt.
        </p>
        <p>
          Habe ich Eure Neugier geweckt?
        </p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AboutMePage',
}
</script>
