<template>
  <WscHeader/>
  <router-view></router-view>
  <WscFooter/>
</template>

<script>
import WscHeader from './layout/header.vue'
import WscFooter from './layout/footer.vue'

export default {
  name: 'App',
  components: {
    WscHeader,
    WscFooter
  }
}
</script>

<style>
@import "styles/app.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
