<template>
  <div id="footer">
    <img class="rose" src="../assets/graphics/Pfingstrose_2.png" alt="">
    <div class="main-content closing-words" :class="{'aboutme': showFooterImages}">
      <p>Mehr zu meiner täglichen Arbeit und mir erfahrt Ihr auf Facebook und Instagram.</p>
      <div id="social-media">
        <div>
          <a href="https://www.facebook.com/cor.inna.33" target="_blank" aria-label="Zur Facebookseite wechseln">
            <img src="../assets/icon-fb.png" alt="facebook-icon">
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/corinnaparr/" target="_blank" aria-label="Zur Instagramm wechseln">
            <img src="../assets/icon-instagram.png" alt="instagram-icon">
          </a>
        </div>
      </div>
      <div v-if="showFooterImages" class="footer-images-wrapper">
        <img src="../assets/images/footer_1.jpg" alt="" class="footer-image">
        <img src="../assets/images/footer_2.jpg" alt="" class="footer-image">
        <img src="../assets/images/footer_3.jpg" alt="" class="footer-image">
      </div>
    </div>

    <div class="agb">
      <p class="rights"> ® Alle Rechte vorbehalten </p>
      <p class="agb-links">Impressum<span>|</span>Datenschutz</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WscFooter',
  computed: {
    showFooterImages() {
      return this.$route.path === '/aboutme'
    },
  },
}
</script>
<style scoped>


#footer {
  height: 600px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.rose {
  z-index: -1;
  width: 665px;
  transform: scale(-1, 1);
  bottom: -60px;
  left: -50%;
  position: absolute;
}

.closing-words {
  margin-top: 4em;
  text-align: center;
}

.aboutme {
  margin-top: 0 !important;
}

#social-media {
  display: flex;
  justify-content: center;
}

#social-media div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /*background-color: var(--primary-400);*/
  margin-right: 20px;
}

.agb {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2em 3em;

}

.rights {
  margin-bottom: 0.5em;
}

.agb span {
  margin: 0 0.5em;
  font-weight: 400;
}

.agb-links {
  font-family: BrandonTextBold, Fallback, sans-serif;
  color: var(--primary-400);
  /*font-weight: 900;*/
}

.main-content > * + *{
  margin-top: 2em !important;
}

p {
  color: var(--gray);
  font-weight: 500;
  font-size: clamp(1rem, 2vw + 0.5rem, 1.25rem);
}

.footer-images-wrapper {
  margin: auto;
  /*max-width: 300px;*/
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.footer-image {
  aspect-ratio: auto;
  object-fit: fill;
  max-width: 30%;
}

@media (min-width: 391px) {
  .footer-images-wrapper {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .footer-images-wrapper {
    max-width: 700px;
    flex-direction: row;
  }

  .closing-words {
    margin-top: 10em;
  }

  .aboutme {
    margin-top: 4em !important;
  }

  .agb {
    padding: 3em 10em;
  }

  .rose {
    width: 865px;
    bottom: -185px;
    left: -390px;
  }

}


@media (min-width: 1280px) {
  .footer-images-wrapper {
    max-width: 800px;
    flex-direction: row;
  }
}
</style>
