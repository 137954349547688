<template>
  <div class="img-container">
    <img class="focus" src="../assets/images/Hochzeit-590.jpg" alt="">
    <img class="focus" src="../assets/images/afe2a620-6750-4826-ad7c-3a408748f379.jpg" alt="">
    <img class="focus" src="../assets/images/PortraitMitMikrofon.jpg" alt="">
  </div>

</template>


<script>
export default {
  name: 'ImageBanner',
}
</script>

<style scoped>

.img-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 40% 1fr;
  column-gap: 1em;
}


.img-container img{
  width: 200%;
  height: 150px;
  object-fit: cover;
}

.focus {
  object-position: 50% 25%;
}


@media (min-width: 768px) {
  .img-container{
    column-gap: 2em;
  }

  .img-container img{
    height: 300px;
  }
}

</style>
