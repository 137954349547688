<template>
  <div id="start" class="body-wrapper">
    <div class="main-content">
      <h1 class="headline-quote">
        HEIRAT IST NICHT DAS HAPPY END
        – SIE IST IMMER ERST EIN ANFANG.
      </h1>
      <div class="author">
        <p> (Federico Fellini)</p>
      </div>
      <div class="context">


        <p>
          Ich freue mich, dass Ihr den Weg zu mir gefunden habt.
        </p>
        <p>
          Ihr plant in naher Zukunft zu heiraten? Herzlichen Glückwunsch zur Verlobung.<br>
          Ab jetzt beginnt eine aufregende und emotionale Zeit der Entscheidungen und Vorbereitungen.
        </p>
        <p>
          Vielleicht habt Ihr bereits Vorstellungen wie Euer besonderer Tag ablaufen soll und
          seid lediglich auf der Suche nach einer Traurednerin für Eure Trauung oder
          Ihr seid einfach nur neugierig geworden über die Möglichkeiten und den Ablauf einer Freien Trauung? <br>
          Egal aus welchem Grund Ihr auf mich aufmerksam wurdet – bei mir seid Ihr genau richtig.
        </p>
        <p>
          Stöbert gerne durch die nächsten Seiten und lasst Euch verzaubern.
          Ich freue mich, wenn ich Euch inspirieren und von einer Freien Trauung überzeugen kann.
        </p>
        <p>
          Sprecht mich gerne an!
        </p>
      </div>
      <div class="signature"> Eure <span>Corinna</span></div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'StartPage',
}
</script>

<style scoped>
.headline-quote {
  letter-spacing: 0.1em;
}

@media (min-width: 768px) {
  .headline-quote {
    letter-spacing: 0.2em;
  }
}

.author {
  color: var(--gray);
  display: flex;
  justify-content: flex-end;
}

.signature {
  color: var(--gray);
  text-align: center;
}

.signature span {
  font-family: 'Yonitta', Fallback, sans-serif;
  font-size: 4em;
  color: var(--primary-500);
}
</style>
