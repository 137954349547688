<template>
  <div id="contact" class="body-wrapper">
    <div class="main-content" style="position: relative">
      <WscNotification :is-hidden="!displayNotification" :success="success"/>
      <h1>
        KONTAKT
      </h1>
      <div class="context">
        <p>
          Möchtet Ihr mich kennenlernen oder habt Ihr vorab Fragen? Hinterlasst mir bitte Eure Kontaktdaten.
          Telefonisch stehe ich gerne nach einer ersten Kontaktaufnahme über das Formular zur Verfügung.
        </p>
      </div>
      <fieldset :disabled="!canEdit">
        <form class="contact-form" ref="form" @submit.prevent="sendEmail">
          <div id="contacts">
            <label for="from_name">Dein Name<span>*</span>
              <input id="from_name" name="from_name" v-model="email"/>
            </label>
            <label for="email">E-Mail<span>*</span>
              <input id="email" name="reply_to" v-model="name"/>
            </label>
            <label for="phone">Telefon
              <input id="phone" style="margin: 0"/>
            </label>
          </div>
          <div id="message-container">
            <label for="message">Deine Nachricht an mich<span>*</span>
              <br>
              <textarea id="message" name="message" v-model="message"></textarea>
            </label>
          </div>
          <div id="cta">
            <div>
              <button v-bind:disabled="!canEdit" :class="{'disable-cta': !canEdit}">SENDEN</button>
              <span v-if="incomplete">Füllen Sie bitte alle mit * markierten Felder aus.</span>
            </div>
          </div>
        </form>
      </fieldset>
    </div>
  </div>
</template>


<script>
import emailjs from '@emailjs/browser';
import WscNotification from '../components/notification.vue'

export default {
  name: 'ContactPage',
  data() {
    return {
      canEdit: true,
      incomplete: false,
      email: '',
      name: '',
      message: '',
      displayNotification: false,
      success: true,
    }
  },
  components: {
    WscNotification
  },
  methods: {
    showNotification() {
      this.displayNotification = true;
      setTimeout(() => this.displayNotification = false, 4000);
    },
    sendEmail() {
      if (!(this.email && this.name && this.message)) {
        this.incomplete = true;
        return;
      }
      this.incomplete = false;


      try {
        emailjs.sendForm(
            //process.env.VUE_APP_EMAILJS_SERVICE_ID,
            'service_gmbzgn6',
            //process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
            'template_mna1q2h',
            this.$refs.form,
            //process.env.VUE_APP_EMAILJS_PUBLIC_KEY
            '55tLLD4YvpFQoOPQy'
        ).then((result) => {
          this.canEdit = false
          this.showNotification()
          console.log('SUCCESS!', result.text);
        })
      } catch (error) {
        this.success = false;
        this.showNotification()
        console.log('FAILED...', error);
      }
    }
  }
}
</script>
<style scoped>

.contact-form {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 2em;
  row-gap: 1em;
  grid-template:
      "contacts"
      "message"
      "cta";
}

#contacts {
  grid-area: contacts;
}

#message-container {
  grid-area: message;
}

#cta {
  grid-area: cta;
  display: flex;
  justify-content: flex-end;
}

label {
  color: var(--gray);
}

input, textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--primary-400);
  margin-bottom: 1em;
  padding: 0.5em;
}

textarea {
  margin-bottom: 0;
  height: 90%;
}

span {
  color: var(--important-400);
}

button {
  background-color: var(--primary-400);
  opacity: 70%;
  font-family: BrandonTextRegular, Fallback, sans-serif;
  font-size: 1.2rem;
  color: var(--white);
  border: none;
  padding: 0.1em 2.5em;
  margin-top: 0.5em;
}

button:hover {
  opacity: 80%;
  cursor: pointer;
}

#cta span {
  display: block;
  font-size: 0.75em;
}

.disable-cta:hover {
  opacity: 70%;
  cursor: not-allowed;
}


@media (min-width: 768px) {

  .contact-form {
    grid-template-columns: 50%;
    grid-template:
      "contacts message"
      ". cta";
  }

  button {
    font-size: 1.75rem;
    letter-spacing: 0.1em;
    margin-top: 0;
  }
}
</style>
