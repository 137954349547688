<template>
  <div id="ablauf" class="body-wrapper">
    <div class="main-content">
      <div class="dotted-container dotted-top dotted-right">
        <h1>
          ABLAUF
        </h1>
        <h2> Kennenlernen </h2>
        <p>
          Wir treffen uns ganz unverbindlich zu einem gemeinsamen kostenfreien Erstgespräch. <br>
          Bei diesem Gespräch finden wir heraus, ob wir zueinander passen.
          Solltet Ihr nicht in München und Umgebung wohnen,
          können wir unser Kennenlernen gerne über Videotelefonie (Teams, Zoom, ...) ermöglichen.
        </p>
        <p>
          Mir ist wichtig, dass wir gut miteinander harmonieren.
          Eine Freie Trauung steht und fällt mit Sympathie und Vertrauen.
          Wichtig ist, dass Ihr Euren besonderen Tag für immer mit einem Lächeln im Herzen tragt.
        </p>
        <div class="flower-decor-wrapper">
          <img class="flower-decor" src="../assets/grafic-flower.png" alt=""/>
        </div>
      </div>
      <div class="dotted-container dotted-top dotted-left">
        <h2> Begleitung </h2>

        <p>
          Eure Wünsche und Vorstellungen stehen bei mir im Mittelpunkt.
          Ab dem Zeitpunkt unseres Kennenlernens bis zum Tage Eurer Freien Trauung stehe ich Euch
          mit all Euren Wünschen und Ideen zu jeder Zeit gerne zur Verfügung.
        </p>

        <div class="flower-decor-wrapper">
          <img class="flower-decor" src="../assets/grafic-flower.png" alt=""/>
        </div>
      </div>
      <div class="dotted-container dotted-top dotted-right">
        <h2> Traugespräch </h2>

        <p>
          Während unseres gemeinsamen Gespräches tauchen wir in
          Euer Leben ein und greifen dabei schöne und bewegende Momente auf.
          Meine Traureden leben von den feinen Nuancen Eurer Lebensgeschichte.
        </p>
        <p>
          Eine Freie Trauungszeremonie ist so individuell wie Ihr. Gemeinsam erarbeiten wir uns Euren perfekten Tag.
        </p>
        <p>
          Nach unserem Traugespräch erhaltet Ihr von mir Euren individuellen Ablaufplan.
        </p>

        <div class="flower-decor-wrapper">
          <img class="flower-decor" src="../assets/grafic-flower.png" alt=""/>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="spacer dotted-top dotted-left"></div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ProcedurePage',
}
</script>

<style scoped>

h1 {
  background-color: white;
  position: absolute;
  padding: 0.25em 0.25em 0.25em 1.5em;
  top: -60px;
  left: -10px
}

h2 {
  font-family: 'Yonitta', Fallback, sans-serif;
  color: var(--primary-500);
  font-size: clamp(2rem, 3vw + 1rem, 3.5rem);
  font-weight: lighter;
  text-align: center;
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.dotted-top {
  border-top: 5px dotted var(--primary-400);
}

.dotted-container {
  padding: 2em 2.5em;
  position: relative;
}

.dotted-container:first-child {
  padding-right: 2.5em;
}

.dotted-container p {
  margin-bottom: 2em;
  color: var(--gray);
  font-weight: 500;
  font-size: clamp(1rem, 2vw + 0.5rem, 1.25rem);
}

.dotted-container p:last-child {
  margin-bottom: 0;
}


.dotted-right {
  border-right: 5px dotted var(--primary-400);
}

.dotted-left {
  border-left: 5px dotted var(--primary-400);
}

.spacer {
  width: 50%;
  height: 200px;
  display: inline-block;
}

.main-content {
  margin-top: 3em;
}


.flower-decor-wrapper {
  /*content: '';*/
  /*background: url("../assets/grafic-flower.png");*/
  background-size: cover;
  width: 80px;
  height: 80px;
  bottom: -45px;
  position: absolute;
  text-align: center;
  left: 50%;
  z-index: 1;
}

.flower-decor {
  position: absolute;
  left: -50%;
  border: 15px solid white;
  background-color: white;
}

@media (min-width: 768px) {
  .dotted-container {
    padding: 3em 5.5em;
    position: relative;
  }

  .dotted-container:first-child {
    padding-right: 4.5em;
  }
}

@media (min-width: 1300px) {
  h1 {
    top: -75px;
  }

  .main-content {
    margin-top: 5em;
  }


}


</style>
