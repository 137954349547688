import {createApp} from 'vue'
import {createRouter, createWebHistory} from "vue-router";
import App from './App.vue'
import AboutMePage from './pages/aboutme.vue'
import ContactPage from './pages/contact.vue'
import ProcedurePage from './pages/procedure.vue'
import StartPage from './pages/start.vue'
import WeddingPage from './pages/wedding.vue'

const routes = [
  {path: '/', component: StartPage},
  {path: '/aboutme', component: AboutMePage},
  {path: '/contact', component: ContactPage},
  {path: '/ablauf', component: ProcedurePage},
  {path: '/trauung', component: WeddingPage}
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
})
createApp(App)
    .use(router)
    .mount('#app')

