<template>
  <div class="notification-wrapper" :class="isHidden ? '' : 'fadeIn'">
    <div class="notification" :class="success? 'success' : 'error'" :style="isHidden? 'display:none' : ''">
      <p v-if="success">Ihre Email wurde verschickt.</p>
      <p v-else>Ihre Email konnte leider nicht versendet werden.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WscNotification',
  props: {
    success: {
      type: Boolean,
      default: true,
    },
    isHidden: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
<style>
.notification-wrapper {
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  pointer-events: none;
  opacity: 1;
}

.fadeIn{
  animation: fade 100ms linear;
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.notification {
  max-width: 400px;
  width: fit-content;
  height: fit-content;
  padding: 0.5em 1em;
  top: 100px;
  margin: auto;
  position: sticky;
  color: white;
  background-color: darkgray;
  border-radius: 4px;
  z-index: 100;
}

.hidden {
  display: none;
}

.success {
  background-color: var(--primary-400);
}

.error {
  background-color: #e54d42;
}
</style>
