<template>
  <div id="trauung" class="body-wrapper">
    <div class="main-content">
      <h1>
        FREIE TRAUUNG
      </h1>
      <div class="context">
        <p>
          Als Alternative zur kirchlichen Hochzeit stellt die Freie Trauung eine symbolische Zeremonie dar,
          um eurem Partner das JA-Wort zu geben. In der gemeinsamen Gestaltung sind wir völlig frei.
          Ihr wünscht etwas Außergewöhnliches, fernab fester Abläufe und Strukturen, bei der Eure ganz persönliche
          Geschichte im Vordergrund steht - dann ist eine Freie Trauung genau das richtige.
        </p>
        <p>
          Egal ob am See, auf dem Berg, im Wald, bei euch zu Hause im Garten, am Strand, oder am Ort Eures Kennenlernens
          - Ich begleite Euch sowohl im In- als auch Ausland an den Ort, an dem Ihr Euch das Eheversprechen geben
          möchtet.
        </p>
        <p>
          Bitte beachten: Eine Freie Trauungszeremonie ersetzt keine standesamtliche Eheschließung.
        </p>
        <p>
          Ich halte meine Trauungen vordergründig in deutscher Sprache. Für eine zweisprachige Trauung auf Deutsch und
          Englisch biete ich gern eine synchrone Übersetzung durch einen Übersetzer (Muttersprache) an.
          Übersetzungen in weitere Sprachen gerne auf Nachfrage. Mit Freude heiße ich andere Kulturen bei mir
          willkommen.
        </p>
      </div>
    </div>
    <div class="img-banner-wrapper">
      <ImgBanner></ImgBanner>
    </div>
  </div>
</template>


<script>
import ImgBanner from "@/components/ImgBanner";

export default {
  name: 'WeddingPage',
  components: {
    ImgBanner
  }
}
</script>

<style scoped>

.img-banner-wrapper {
  width: 100%;
  grid-column: span 12;
  margin-top: 5em;
  margin-bottom: 2em;
}

</style>
